export const cheatcodesStackNavigatorConfig = {
  CheatcodesStackNavigator: {
    initialRouteName: 'cheatcodes',
    screens: {
      CheatcodesMenu: 'cheatcodes',
      CheatcodesNavigationAccountManagement: 'cheatcodes/other/account-management',
      CheatcodesNavigationAchievements: 'cheatcodes/achievements',
      CheatcodesNavigationBirthdayNotifications: 'cheatcodes/birthday-notifications',
      CheatcodesNavigationBookOffer: 'cheatcodes/book-offer',
      CheatcodesNavigationBookings: 'cheatcodes/bookings',
      CheatcodesNavigationCulturalSurvey: 'cheatcodes/cultural-survey',
      CheatcodesNavigationErrors: 'cheatcodes/other/errors',
      CheatcodesNavigationForceUpdate: 'cheatcodes/other/force-update',
      CheatcodesScreenBookingNotFound: 'cheatcodes/bookings/booking-not-found',
      CheatcodesScreenGenericInfoPage: 'cheatcodes/other/generic-info-page',
      CheatcodesScreenGenericErrorPage: 'cheatcodes/other/generic-error-page',
      CheatcodesScreenSecondaryPageWithBlurHeader:
        'cheatcodes/other/secondary-page-with-blur-header',
      CheatcodesScreenGenericOfficialPage: 'cheatcodes/other/generic-official-page',
      CheatcodesNavigationGenericPages: 'cheatcodes/other/generic-pages',
      CheatcodesNavigationHome: 'cheatcodes/home',
      CheatcodesNavigationIdentityCheck: 'cheatcodes/identity-check',
      CheatcodesNavigationInternal: 'cheatcodes/internal',
      CheatcodesNavigationNewIdentificationFlow:
        'cheatcodes/identity-check/new-identification-flow',
      CheatcodesNavigationNotScreensPages: 'cheatcodes/other/not-screens-pages',
      CheatcodesNavigationOnboarding: 'cheatcodes/tutorial/onboarding',
      CheatcodesNavigationProfile: 'cheatcodes/profile',
      CheatcodesNavigationProfileTutorial: 'cheatcodes/tutorial/profile-tutorial',
      CheatcodesNavigationShare: 'cheatcodes/share',
      CheatcodesNavigationSignUp: 'cheatcodes/other/sign-up',
      CheatcodesNavigationSubscription: 'cheatcodes/subscription',
      CheatcodesNavigationTrustedDevice: 'cheatcodes/trusted-device',
      CheatcodesNavigationTutorial: 'cheatcodes/tutorial',
      CheatcodesScreenAccesLibre: 'cheatcodes/other/acces-libre',
      CheatcodesScreenLayoutExpiredLink: 'cheatcodes/other/layout-expired-link',
      CheatcodesScreenCategoryThematicHomeHeader: 'cheatcodes/home/category-thematic-home-header',
      CheatcodesScreenDebugInformations: 'cheatcodes/other/debug-informations',
      CheatcodesScreenDefaultThematicHomeHeader: 'cheatcodes/home/default-thematic-home-header',
      CheatcodesScreenFeatureFlags: 'cheatcodes/other/feature-flags',
      CheatcodesScreenHighlightThematicHomeHeader: 'cheatcodes/home/highlight-thematic-home-header',
      CheatcodesScreenMaintenance: 'cheatcodes/maintenance',
      CheatcodesScreenNewCaledonia: 'cheatcodes/other/new-caledonia',
      CheatcodesScreenRemoteBanners: 'cheatcodes/remote-banners',
      CheatcodesScreenRemoteConfig: 'cheatcodes/other/remote-config',
      CheatcodesScreenTrustedDeviceInfos: 'cheatcodes/trusted-device/trusted-device-infos',
      CheatcodesScreenNotEligibleEduConnect: 'cheatcodes/identity-check/not-eligible-educonnect',
    },
  },
}
